import React, { useContext, useEffect, useState } from 'react'

import Seo from '../components/SEO/Seo'
import Cart from '../components/Cart/Cart'

import { CartContext } from '../context/CartContext'
import Heading from '../components/Heading/Heading'
import { GTViewCart } from '../helpers/gtagEvents'

const CartPage = ( { location } ) => {

	const { cart } = useContext( CartContext );
	const [ hasMounted, setHasMounted ] = useState(false);

	useEffect(() => {
		if ( !hasMounted ) {
			setHasMounted( true )
			GTViewCart( cart )
		}
	}, [ cart, hasMounted ] )

	if ( !hasMounted ) {
		return null;
	}

	return (
		<>
			<Seo title="Cart" pathname={ location.pathname } />

			{ cart && cart.length > 0 ? (
				<Cart />
			) : (
				<div className="cart">
					<Heading heading='Your Art Cart is Empty' />

					<div className="wrapper">
						<h3 className="cart__empty">Add items to your Art Cart to get shopping!</h3>
					</div>
				</div>
			)}
		</>
	)
}

export default CartPage;