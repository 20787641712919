import React, { useContext, useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { CartContext } from '../../context/CartContext';

import { formatPrice } from '../../helpers/currency';
import { cartSubTotal } from '../../helpers/cart'

export default function Shipping( props ) {

	const data = useStaticQuery(graphql`
		query ShippingQuery {
			strapi {
				postage {
					freePostageOver
					postage {
						id
						name
						price
					}
				}
			}
		}
	` )

	const { priceOnly } = props
	const { cart, postage, setPostage, offers } = useContext( CartContext );
	const shipping = data.strapi.postage
	const [ subTotal, setSubTotal ] = useState(0)

	useEffect(() => {
		let subTotal = cartSubTotal( cart, offers )

		setSubTotal( subTotal )

		if ( subTotal >= shipping.freePostageOver ) {
			setPostage({
				price: 0,
				free: true,
				id: 0
			})
		} else {
			setPostage({
				price: shipping.postage[ 0 ].price,
				id: shipping.postage[ 0 ].id
			})
		}

	}, [ cart, shipping, setPostage, offers ])

	const handlePostage = event => {
		const value = event.target.value

		if ( value === 'freePostage' ) {
			setPostage({
				price: 0,
				free: true,
				id: 0
			})
		} else {
			const price = parseFloat( value )
			const id = parseFloat( event.target.id )
			setPostage( { price, id } )
		}
	}

	if ( priceOnly ) {
		return (
			<>
				{ !postage.free ? (
					<div>{ formatPrice( postage.price ) }</div>
				) : (
					<div>Free</div>
				) }
			</>
		)
	}
	
	return (
		<>
			{ subTotal >= shipping.freePostageOver ? (
				<>
					<div className="form__input-container">
						<label className="form__checkbox-label" htmlFor="freePostage">
							<input
								className="form__checkbox-input"
								type="radio"
								name="shipping"
								id="freePostage"
								value="freePostage"
								defaultChecked={ true }
								onClick={ e => handlePostage(e) } />
							<div className="form__checkbox-checkmark"></div>
							<span className="form__checkbox-text">Free Postage</span>
						</label>
					</div>
				</>
			) : (
				<>
					{ shipping.postage.map( ( postage, index ) => (
						<div className="form__input-container" key={ postage.id }>
							<label className="form__checkbox-label" htmlFor={ postage.id }>
								<input
									className="form__checkbox-input"
									type="radio"
									name="shipping"
									id={ postage.id }
									value={ postage.price }
									onClick={ e => handlePostage(e) }
									defaultChecked={ !index ? true : false } />
								<div className="form__checkbox-checkmark"></div>
								<span className="form__checkbox-text">{ postage.name } - { formatPrice( postage.price ) }</span>
							</label>
						</div>
					) )	}
				</>
			) }
		</>
	)
}
