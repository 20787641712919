import React, { useContext } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import { CartContext } from '../../context/CartContext';

import Shipping from '../Shipping/Shipping'
import Heading from '../Heading/Heading'

import { formatPrice } from '../../helpers/currency';
import { cartSubTotal, cartTotal } from '../../helpers/cart';

import PlusIcon from '../../images/svgs/plus-icon.svg'
import MinusIcon from '../../images/svgs/minus-icon.svg'

export default function Cart() {

	const { cart, addToCart, removeFromCart, postage, offers, hasOfferApplied } = useContext( CartContext );

	const incrementQuantity = ( increment, product ) => {
		addToCart( product, increment, false)
	}

	return (
		<div className="cart">

			<Heading heading="Your Art Cart" />

			<div className="wrapper">

				{ cart.map( ( product, index ) => {
					const productHasOffer = hasOfferApplied.some( offer => offer.name === product.category.name ) && product.offerPrice
					const showFramed = product.category.name === 'Prints' || product.category.name === 'Originals'

					return (
						<div className="cart__product" key={ index }>
							<Link className="cart__product-link" to={`/${ product.slug }/`}>
								<div className="cart__image-container">
									<GatsbyImage
										image={product.images[0].imageFile.childImageSharp.gatsbyImageData}
										className="cart__image"
										objectFit="contain"
										loading="lazy"
										alt={ product.name }
									/>
								</div>
							
								<div className="cart__product-info cart__product-info--name">
									<div className="cart__product-name">{ product.name }</div>
									{ product.medium && <div className="cart__product-meta">{ product.medium }</div> }
									{ product.dimensions && <div className="cart__product-meta">{ product.dimensions }</div> }
									{ showFramed &&
										<div className="cart__product-meta">{ product.framed ? 'Framed' : 'Unframed' }</div>
									}
								</div>
							</Link>
							<div className="cart__product-info">
								<span className="cart__product-title">Quantity</span>
								<div className="cart__quantity-container">
									<button className={`cart__quantity-trigger ${ product.quantity === 1 ? 'disabled' : '' }`} onClick={ () => incrementQuantity( -1, product ) }>
										<MinusIcon className="cart__quantity-icon" />
									</button>
									<div
										type="number"
										className="cart__quantity-input">
										{ product.quantity }
									</div>
									<button className={`cart__quantity-trigger ${ product.quantity === product.quantityInStock ? 'disabled' : ''}`} onClick={ () => incrementQuantity( 1, product ) }>
										<PlusIcon className="cart__quantity-icon" />
									</button>
								</div>
							</div>

							<div className="cart__product-info">
								<span className="cart__product-title">Total</span>
								<div className={ `cart__product-price ${ productHasOffer ? 'offer' : '' }`}>{ formatPrice( product.price ) }</div>
								{ productHasOffer &&
									<div className="cart__product-price">{ formatPrice( product.offerPrice ) }</div>
								}
							</div>

							<div className="cart__product-info cart__product-info--remove">
								<span className="cart__product-title">Delete Product</span>
								<PlusIcon className="cart__product-remove" onClick={ () => removeFromCart( product ) } />
							</div>
						</div>
					)
				} ) }

				<div className="cart__price-container">
					<div className="cart__subtotal">
						Subtotal
						<span className="cart__subtotal-price">{ formatPrice( cartSubTotal( cart, offers ) ) }</span>
					</div>
					<div className="cart__subtotal">
						Delivery
						<span className="cart__subtotal-shipping"><Shipping priceOnly={ true } /></span>
					</div>
				</div>
				<div className="cart__total-container">
					<div className="cart__subtotal">
						Grand Total
						<span className="cart__subtotal-price">{ formatPrice( cartTotal( cart, postage, offers ) ) }</span>
					</div>
				</div>
				<Link to="/checkout/" className="cart__button">
					Go to Checkout
				</Link>
			</div>
		</div>
	)
}
